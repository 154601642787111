import React, { useEffect, useRef } from "react";

const NieveEffect = ({ canvasRef }) => {
  const flakes = useRef([]);
  const animationFrameId = useRef(null);

  const reset = (flake) => {
    // Los copos se distribuyen en toda la pantalla (incluso si el usuario hace scroll)
    flake.x = Math.random() * window.innerWidth;
    flake.y = -10; // Iniciar un poco por encima de la pantalla
    flake.size = Math.random() * 3 + 2;
    flake.speed = Math.random() * 1 + 0.3;
    flake.velY = flake.speed;
    flake.velX = Math.random() * 2 - 1; // Movimiento horizontal aleatorio
    flake.opacity = Math.random() * 0.5 + 0.3;
  };

  const snow = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    for (let flake of flakes.current) {
      // Movimiento de la nieve
      flake.y += flake.velY;
      flake.x += flake.velX;

      // Si el copo se sale por debajo o por los lados, lo reiniciamos
      if (flake.y >= window.innerHeight) {
        reset(flake);
      }

      if (flake.x >= window.innerWidth || flake.x <= 0) {
        reset(flake);
      }

      // Dibuja el copo de nieve
      ctx.fillStyle = `rgba(255, 255, 255, ${flake.opacity})`;
      ctx.beginPath();
      ctx.arc(flake.x, flake.y, flake.size, 0, Math.PI * 2);
      ctx.fill();
    }

    animationFrameId.current = requestAnimationFrame(snow);
  };

  const init = () => {
    const canvas = canvasRef.current;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    for (let i = 0; i < 400; i++) {
      let flake = {
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight,
        size: Math.random() * 3 + 2,
        speed: Math.random() * 1 + 0.3,
        velY: Math.random() * 1 + 0.3,
        velX: Math.random() * 2 - 1, // Movimiento horizontal aleatorio
        opacity: Math.random() * 0.5 + 0.3,
      };
      flakes.current.push(flake);
    }

    snow();
  };

  useEffect(() => {
    init();

    // Limpiar el efecto cuando el componente se desmonte
    return () => {
      cancelAnimationFrame(animationFrameId.current);
    };
  }, []);

  // Envolver el canvas en un div con estilo para evitar problemas de scroll
  return (
    <canvas
      ref={canvasRef}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw", // Aseguramos que el canvas cubra todo el ancho de la pantalla
        height: "100vh", // Aseguramos que el canvas cubra todo el alto de la pantalla
        zIndex: 9999,
        pointerEvents: "none",
        overflow: "hidden", // Asegura que no haya scroll
      }}
    />
  );
};

export { NieveEffect };
