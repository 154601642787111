import React, { useRef, useState, useEffect } from "react";
import music from "./../../assets/music/Efectos_de_Sonidos_Cascabeles_Navideños_2020.mp3";

const MusicPlayer = () => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayAudio = () => {
    const audio = audioRef.current;
    if (audio && !isPlaying) {
      audio.muted = false;
      audio
        .play()
        .then(() => {
          setIsPlaying(true); // Cambiar el estado a 'reproduciendo'
        })
        .catch((err) => {
          console.log("Error al intentar reproducir el audio:", err);
        });
    }
  };

  // Manejo del evento `ended` para reiniciar la reproducción del audio
  const handleAudioEnd = () => {
    const audio = audioRef.current;
    if (audio) {
      audio.currentTime = 0; // Reiniciar la reproducción desde el principio
      audio.play(); // Reproducir el audio de nuevo
    }
  };

  useEffect(() => {
    document.addEventListener("mouseover", handlePlayAudio);
    document.addEventListener("mousemove", handlePlayAudio);

    // Agregar el evento `ended` para reiniciar la música cuando termine
    const audio = audioRef.current;
    if (audio) {
      audio.addEventListener("ended", handleAudioEnd);
    }

    return () => {
      document.removeEventListener("mouseover", handlePlayAudio);
      document.removeEventListener("mousemove", handlePlayAudio);
      if (audio) {
        audio.removeEventListener("ended", handleAudioEnd);
      }
    };
  }, [isPlaying]);

  return (
    <audio
      ref={audioRef}
      loop
      autoPlay
      muted={true} // Comienza silenciado, se desmutea al interactuar
    >
      <source src={music} type="audio/mpeg" />
      Tu navegador no soporta el formato de audio.
    </audio>
  );
};

export { MusicPlayer };
